// Import components
import HomeCountdown from './countdown/Countdown.js';

// Import images
import ImgHeader1 from '../../../../assets/img/header1.png';

const HomePart1 = (className) => {

	return (
		<section className="container home-header home-header-v2 overflow-show">
			<div className="container-core">

				{/* Title section for features section */}
				<div className="container-row">

					<div className="block-footer">

						<div className="block-text header-mint-notice">
							{/* <p><span class="header-mint-icon">🚀</span><span className="header-mint-text">Mint or miss out!</span></p> */}
							{/* <p><span class="header-mint-icon">🚀</span><span className="header-mint-text">Floor > Mint!</span></p> */}
							<p><span className="header-mint-icon">🚀</span><span className="header-mint-text">SOLD OUT!</span></p>
						</div>

						{/* <HomeCountdown /> */}

					</div>

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>Hyper Exclusive 100 NFTs!</span></h5>
						<h2 className="title-section font-spaceboy">Tubby Alien</h2>
					</div>

					<div className="block-body">

						<div className="block-image">
							<img src={ImgHeader1} alt="" />
						</div>

					</div>

				</div>

			</div>
		</section>
	);
};

export default HomePart1;
