// Import images
import CompBlockCard from '../../../block/Card.js';

// Import featured
import ImgFeaturedTools from '../../../../assets/img/featured/rarity-tools.png';
import ImgFeaturedTools_2x from '../../../../assets/img/featured/rarity-tools-2x.png';
import ImgFeaturedSniper from '../../../../assets/img/featured/rarity-sniper.png';
import ImgFeaturedSniper_2x from '../../../../assets/img/featured/rarity-sniper-2x.png';
import ImgFeaturedJet from '../../../../assets/img/featured/nft-jet.png';
import ImgFeaturedJet_2x from '../../../../assets/img/featured/nft-jet-2x.png';
import ImgFeaturedCalendar from '../../../../assets/img/featured/nft-calendar.png';
import ImgFeaturedCalendar_2x from '../../../../assets/img/featured/nft-calendar-2x.png';
import ImgFeaturedDrop from '../../../../assets/img/featured/next-nft-drop.png';
import ImgFeaturedDrop_2x from '../../../../assets/img/featured/next-nft-drop-2x.png';
import ImgFeaturedUpcoming from '../../../../assets/img/featured/upcoming-nft.png';
import ImgFeaturedUpcoming_2x from '../../../../assets/img/featured/upcoming-nft-2x.png';

const HomeWhy = (className) => {

	return (
		<section id="home-featured" className="container home-featured overflow-show">
			<div className="container-core">

				{/* Title section for why section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>Featured on...</span></h5>
						{/* <h2 className="title-subsection font-spaceboy">Featured on...</h2> */}

						{/* <div className="block-text title-text">
              <p>Tubby Aliens are here to takeover, and want you to be a part of this amazing journey!</p>
            </div> */}
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row block-grid grid-col-6 grid-row-gap-60 grid-column-gap-60 why-grid w1024-grid-col-3 w480-grid-col-2">

					<div className="block-image">
						<img src={ImgFeaturedTools} srcSet={ImgFeaturedTools_2x + " 2x"} alt="" uk-tooltip="title: Rarity Tools; cls: uk-active block-tooltip" />
					</div>
					{/* Rarity Tools */}

					<div className="block-image">
						<img src={ImgFeaturedSniper} srcSet={ImgFeaturedSniper_2x + " 2x"} alt="" uk-tooltip="title: Rarity Sniper; cls: uk-active block-tooltip" />
					</div>
					{/* Rarity Sniper */}

					<div className="block-image">
						<img src={ImgFeaturedJet} srcSet={ImgFeaturedJet_2x + " 2x"} alt="" uk-tooltip="title: NFT Jet; cls: uk-active block-tooltip" />
					</div>
					{/* NFT Jet */}

					<div className="block-image">
						<img src={ImgFeaturedCalendar} srcSet={ImgFeaturedCalendar_2x + " 2x"} alt="" uk-tooltip="title: NFT Calendar; cls: uk-active block-tooltip" />
					</div>
					{/* NFT Calendar */}

					<div className="block-image">
						<img src={ImgFeaturedDrop} srcSet={ImgFeaturedDrop_2x + " 2x"} alt="" uk-tooltip="title: Next NFT Drop; cls: uk-active block-tooltip" />
					</div>
					{/* Next NFT Drop */}

					<div className="block-image">
						<img src={ImgFeaturedUpcoming} srcSet={ImgFeaturedUpcoming_2x + " 2x"} alt="" uk-tooltip="title: Upcoming NFT; cls: uk-active block-tooltip" />
					</div>
					{/* Upcoming NFT */}

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeWhy;
