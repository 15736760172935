import { React, useState } from 'react';

// Import images
import ImgLogoW180 from '../../../assets/img/logo_w180.png';
import ImgLogoW180_2x from '../../../assets/img/logo_w180-2x.png';

import SvgDiscord from '../../../assets/svg/logo-discord.svg';
import SvgTwitter from '../../../assets/svg/logo-twitter.svg';
import SvgOpensea from '../../../assets/svg/logo-opensea.svg';

// Setup component
const HeaderMain = (props) => {
	return (
		<div id="header-main" className="uk-scope wrapper block-flex header-float w800-hide test1">
		<div id="header-main-core" className="wrapper-core">

			{/* UIkit - Navbar */}
			<nav className="uk-navbar-container uk-navbar-transparent uk-navbar" uk-navbar="mode: click; boundary-align: true; align: center; delay-hide: 0">

				<div id="header-main-logo" className="menu-logo uk-navbar-left">
					<span className="uk-navbar-item uk-logo">
						<img alt="Logo" src={ImgLogoW180} srcSet={ImgLogoW180_2x + " 2x"} />
					</span>
				</div>

				<div id="header-main-links" className="menu-links uk-navbar-center">

					<ul className="uk-navbar-nav">

						<li className="menu-item menu-item-top menu-item-link menu-item-welcome">
							<a href="#home-why" uk-scroll="">
								<span className="menu-name">Why</span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-goals">
							<a href="#home-roadmap" uk-scroll="">
								<span className="menu-name">Roadmap</span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-goals">
							<a href="#home-utility" uk-scroll="">
								<span className="menu-name">Utility</span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-rarity">
							<a href="#home-rarity" uk-scroll="">
								<span className="menu-name">rarity</span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-team">
							<a href="#home-team" uk-scroll="">
								<span className="menu-name">team</span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-faq">
							<a href="#home-faq" uk-scroll="">
								<span className="menu-name">faq</span>
							</a>
						</li>

					</ul>

				</div>

				<div id="header-main-social" className="menu-social uk-navbar-right">

					<ul className="uk-navbar-nav block-grid grid-col-3 grid-column-gap-20">

						<li className="menu-item menu-item-top menu-item-link menu-item-team">

							<a className="uk-navbar-item social-link social-opensea" href="https://opensea.io/collection/tubbyalienofficial" target="_blank">
								<img className="social-icon" src={SvgOpensea} width="40" height="40" uk-svg="" />
							</a>

						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-team block-flex">

							<a className="uk-navbar-item social-link social-discord" href="https://discord.gg/knNa8fvkgS" target="_blank">
								<img className="social-icon" src={SvgDiscord} width="40" height="40" uk-svg="" />
							</a>

						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-team block-flex">

							<a className="uk-navbar-item social-link social-twitter" href="https://twitter.com/TubbyAlien" target="_blank">
								<img className="social-icon" src={SvgTwitter} width="40" height="40" uk-svg="" />
							</a>

						</li>

					</ul>

				</div>

			</nav>

		</div>
		</div>
	);
};

export default HeaderMain;
