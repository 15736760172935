const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">How many Tubbly Alien NFTs are there?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>There are only 100 Tubby Alien NFTs. That's 100 EVER! So once they're gone, they're gone. We won't be producing anymore of this edition.</p>
						<p>Plus, 5 NFTs give holders gold voting status and get priority access to giveaways.</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
