import createDOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'

const Card = (props) => {

  // Assign default variable values
  var titleInfo = '';

  // Choose which card style to return
  if (props.style == '' || props.style == 'style1') {

    return (
      <div className={"block-card block-style1 " + props.color + " " + props.class}>

        <div className="block-card-core">

          <div className="block-header">
            <div className={"block-icon block-style1 block-flex block-flex-around " + props.color + " "  + props.color + "-opacity"}>
              <img src={props.icon} alt="" uk-svg="" />
            </div>
          </div>

          <div className="block-body">

            <h3 className="block-title">{props.title}</h3>

            <div className="block-text">
              <p>{props.text}</p>
            </div>

          </div>

        </div>

      </div>
    );

  } else if (props.style == 'style2') {

    return (
      <div className="block-card block-style2">

        <div className="block-card-core">

          <div className="block-header">
            <div className="block-image">
              <img src={props.src} alt="" uk-svg="" />
            </div>
          </div>

          <div className="block-body">

            {/* Custom function to ensure HTML tags don't show */}
            { props.info.indexOf('</') !== -1
              ? (
                  ''
                )
              :
                <h5 className='title-info style1 xff6f91 font-narum'>
                <span dangerouslySetInnerHTML={{__html: props.info.replace(/(<? *script)/gi, 'illegalscript')}} >
                </span>
                </h5>
            }

            <h3 className="block-title">{props.title}</h3>

            <div className="block-text">
              <p>{props.text}</p>
            </div>

          </div>

        </div>

      </div>
    );
  }
};

Card.defaultProps = {
  style : '',
  class : '',
  color : 'x073b4c',
  icon  : '',
  src   : '',
  info  : '',
  title : 'Sample Title Reach',
  text  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
}

export default Card;
