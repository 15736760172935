// Import components
import CompBlockCard from '../../../block/Card.js';
import CompBlockRarity from '../../../block/Rarity.js';

// Import characters
import ImgHeader1 from '../../../../assets/img/header1.png';
import ImgTeam1 from '../../../../assets/img/team/1.png';
import ImgTeam2v1 from '../../../../assets/img/team/2v1.png';
import ImgTeam2v2 from '../../../../assets/img/team/2v2.png';
import ImgTeam3v1 from '../../../../assets/img/team/3v1.png';
import ImgTeam3v2 from '../../../../assets/img/team/3v2.png';
import ImgTeam4v1 from '../../../../assets/img/team/4v1.png';
import ImgTeam4v2 from '../../../../assets/img/team/4v2.png';

// Import svg
import SvgDiscord from '../../../../assets/svg/logo-discord.svg';

const HomeRarity = (className) => {

	return (
		<section id="home-team" className="container home-team overflow-show">
			<div className="container-core">

				{/* Title section for features section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>We got you!</span></h5>
						<h2 className="title-subsection font-spaceboy">The Team</h2>

						<div className="block-text title-text">
              <p>Let's go Tubby Crew! Hop on as we jet to the Moon and beyond! Get on the Tubby Alien rocket before blast off!</p>
              {/* <p>Have an equal chance of getting any Tubby Alien when you mint. With exclusive perks for rare Tubby Alien holders!</p> */}
            </div>
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row block-grid grid-col-4 grid-gap-50 rarity-grid w800-grid-col-2 w480-grid-col-1">

					<CompBlockCard style="style2" src={ImgTeam1} info="Coder Wiz" title="Afal" text="The coding mastermind who makes the magic work." />
					<CompBlockCard style="style2" src={ImgTeam2v2} info="Social Champ" title="sama" text="All over the socials keeping the community happy." />
					<CompBlockCard style="style2" src={ImgTeam3v1} info="Design Guru" title="rort" text="The genius designer who truly is from another world." />
					<CompBlockCard style="style2" src={ImgTeam4v1} info="Character Vision" title="fahi" text="Creates loveable characters that pull at your heart strings." />


				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeRarity;
