const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">What is an NFT?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>NFT stands for non-fungible token and is essentially a digital asset that lives on the blockchain, so once you own it. It's yours forever. Some say it's the future of art, and some say it's the future of currency. We say simply... It's the future!</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
