const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">Why buy a Tubby Alien NFT?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>Get exclusive access to holder only events, online and offline. We're heading for the Metaverse and plan to buy exclusive land, accessible only to Tubby Alien holders. By buying a Tubby Alien NFT you'll be instrumental in launching the Tubby Alien brand!</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
