// Import components
import CompBlockCard from '../../../block/Card.js';
import CompBlockRarity from '../../../block/Rarity.js';

// Import characters
import ImgHeader1 from '../../../../assets/img/header1.png';
import ImgRarity1 from '../../../../assets/img/character/1.png';
import ImgRarity2 from '../../../../assets/img/character/2.png';
import ImgRarity3 from '../../../../assets/img/character/3.png';
import ImgRarity4 from '../../../../assets/img/character/4.png';
import ImgRarity5 from '../../../../assets/img/character/5.png';
import ImgRarity6 from '../../../../assets/img/character/6.png';
import ImgRarity7 from '../../../../assets/img/character/7.png';

// Import svg
import SvgDiscord from '../../../../assets/svg/logo-discord.svg';

const HomeRarity = (className) => {

	return (
		<section id="home-rarity" className="container home-rarity overflow-show">
			<div className="container-core">

				{/* Title section for features section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>We love em all!</span></h5>
						<h2 className="title-subsection font-spaceboy">Rarity</h2>

						<div className="block-text title-text">
              <p>We've created 100 generative art works. With 5 special editions! Plus exclusive perks for rare Tubby Alien holders!</p>
              {/* <p>Have an equal chance of getting any Tubby Alien when you mint. With exclusive perks for rare Tubby Alien holders!</p> */}
            </div>
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row block-grid grid-col-3 grid-gap-30 rarity-grid w480-grid-col-1">

					<CompBlockCard style="style2" src={ImgRarity1} title="Common" text="Meet Goik - They're the backbone of Tubby Alien society, they get stuff done! We'd be lost without them!" />
					<CompBlockCard style="style2" src={ImgRarity2} title="Uncommon" text="Meet Obin - Now these ones can work hard and play hard! They use their hands to get building!" />
					<CompBlockCard style="style2" src={ImgRarity3} title="Uncommon" text="Meet Yend - Maybe these lot are playing a bit too hard when they should be working harder!" />
					<CompBlockCard style="style2" src={ImgRarity4} title="Rare" text="Meet Blew - Every society needs geniuses, and that's exactly what these ones are! The smart thinkers!" />
					<CompBlockCard style="style2" src={ImgRarity5} title="Rare" text="Meet Rous - The protectors of the Tubby Aliens. Leading the charge to discover new alien worlds!" />
					<CompBlockCard style="style2" src={ImgRarity6} title="Epic" text="Meet Whez - The celebs among Tubby Aliens. But they're hard to catch, making up only 0.4% of all Tubby Aliens!" />

					<div className="w480-hide"></div>
					<CompBlockCard style="style2" src={ImgRarity7} title="Legendary" text="Meet Rall - The legends of Tubby Alien society. Do they even exist? Who knows... (yes they do!)" />
					<div className="w480-hide"></div>

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeRarity;
