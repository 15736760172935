const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">How much does a Tubby Alien NFT cost?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>We want everyone to have access to this amazing project. That's why we're keeping our prices low. Starting at 0.03 ETH for whitelisted community members to buy during pre-sale. After pre-sale, you can mint a Tubby Alien NFT for just 0.04 ETH.</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
