// Import images
import CompBlockCard from '../../../block/Card.js';

// Import svg
import SvgNumber1 from '../../../../assets/svg/number-1.svg';
import SvgNumber2 from '../../../../assets/svg/number-2.svg';
import SvgNumber3 from '../../../../assets/svg/number-3.svg';
import SvgNumber4 from '../../../../assets/svg/number-4.svg';

const HomeUtility = (className) => {

	return (
		<section id="home-utility" className="container home-utility overflow-show">
			<div className="container-core">

				{/* Title section for why section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>Real holder value!</span></h5>
						<h2 className="title-subsection font-spaceboy">Utility</h2>

						<div className="block-text title-text">
              <p>Our community is at the heart of this project, and we want you to share in the long-term gains!</p>
            </div>
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row block-grid grid-col-4 grid-row-gap-60 grid-column-gap-30 why-grid w800-grid-col-2 w480-grid-col-1">

					<CompBlockCard
						style = "style1"
						class = "block-card-bg"
						icon  = {SvgNumber1}
						color = {"xfec258"}
						title = "Community Fund"
						text  = "10% of post-tax initial minting income will be held within the community wallet fund."
					/>

					<CompBlockCard
						style = "style1"
						class = "block-card-bg"
						icon  = {SvgNumber2}
						color = {"x5887fe"}
						title = "Commission Share"
						text  = "20% of post-tax commission income will be reinvested back to the community wallet."
					/>

					<CompBlockCard
						style = "style1"
						class = "block-card-bg"
						icon  = {SvgNumber3}
						color = {"xfe6c58"}
						title = "Sell or Stake"
						text  = "You own your NFT and can do with it whatever you want. HODL, sell, stake!"
					/>

					<CompBlockCard
						style = "style1"
						class = "block-card-bg"
						icon  = {SvgNumber4}
						color = {"xc9fe58"}
						title = "Voting Rights"
						text  = "1 NFT = 1 voting right. Each holder has key involvement over the future decisions."
					/>

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeUtility;
