const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">What can I do with my Tubby Alien NFT?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>You can do whatever you want! Hold it, sell it. Really... the choice is yours! We'd love for you to be a long-term valued member of our community, so if you hold the NFT we'd love for you to be with us along this amazing journey we're all taking together!</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
