const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">When will Tubby Alien Club launch?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>Follow us on Twitter and Discord to keep up to take with our launch plan. We'll give you plenty of notice, so don't worry you won't miss out! Also, when we confirm the launch date we'll also update this site.</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
