// Import components
import CompBlockCard from '../../../block/Card.js';
import CompBlockRarity from '../../../block/Rarity.js';

// Import svg
import Faq1 from './faq/Faq1.js';
import Faq2 from './faq/Faq2.js';
import Faq3 from './faq/Faq3.js';
import Faq4 from './faq/Faq4.js';
import Faq5 from './faq/Faq5.js';
import Faq6 from './faq/Faq6.js';
import Faq7 from './faq/Faq7.js';
import Faq8 from './faq/Faq8.js';

const HomeFaq = (className) => {

	return (
		<section id="home-faq" className="container home-faq overflow-show">
			<div className="container-core">

				<div className="container-row">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>FAQ</span></h5>
						<h2 className="title-subsection font-spaceboy">Got a question</h2>

						<div className="block-text title-text">
              <p>Remember to hit us up on Discord and Twitter if there's a question we haven't answered!</p>
              {/* <p>Have an equal chance of getting any Tubby Alien when you mint. With exclusive perks for rare Tubby Alien holders!</p> */}
            </div>
					</div>

					<div className="block-body uk-scope">

						<ul className="uk-accordion block-accordion block-style1" uk-accordion="multiple: true">

							<Faq1 />
							<Faq2 />
							<Faq3 />
							<Faq4 />
							<Faq5 />
							<Faq6 />
							<Faq7 />
							<Faq8 />

						</ul>
						{/* .block-accordion */}

					</div>
					{/* .block-body */}

				</div>
				{/* .container-row */}

			</div>
		</section>
	);
};

export default HomeFaq;
