const Faq1 = () => {
	return (
		<li className="block-accordion-container">

			<h4 className="uk-accordion-title title-primary block-accordion-title">What is the Tubby Alien Club?</h4>

			<div className="uk-accordion-content block-accordion-content" hidden>

				<div className="block-body">
					<div className="block-text">
						<p>Tubby Alien is an exclusive club of 100 unique NFTs where each holder is a valued member of our community. Tubby Aliens are more than just an NFT. They're the start of a revolutionary new brand and character universe. Full of loveable characters for everyone at any age!</p>
					</div>
				</div>

			</div>
			{/* .block-accordion-content */}

		</li>
	);
};

export default Faq1;
