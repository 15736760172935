// Import images
import CompBlockCard from '../../../block/Card.js';

// Import svg
import SvgDiscord from '../../../../assets/svg/logo-discord.svg';
import SvgCheck1 from '../../../../assets/svg/icon-check1.svg';
import SvgCheck2 from '../../../../assets/svg/icon-check2.svg';

const HomeWhy = (className) => {

	return (
		<section id="home-why" className="container home-why overflow-show">
			<div className="container-core">

				{/* Title section for why section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>We're the future!</span></h5>
						<h2 className="title-subsection font-spaceboy">Why Tubby Alien</h2>

						<div className="block-text title-text">
              <p>Tubby Aliens are here to takeover, and want you to be a part of this amazing journey!</p>
            </div>
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row block-grid grid-col-4 grid-row-gap-60 grid-column-gap-30 why-grid w800-grid-col-2 w480-grid-col-1">

					<CompBlockCard style="style1" icon={SvgCheck2} color={"xfec258"} title="Family Friendly" text="We're building a lasting brand with loveable characters. Great for anyone of any age!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"x5887fe"} title="Character Universe" text="Tubby Aliens are just the start. We're creating the next big character universe!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"xfe6c58"} title="Clear Roadmap" text="Our plan is realistic and long-term with a clear commitment and focus for success!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"xc9fe58"} title="Hyper Exclusive" text="Tubby Alien NFT collection is Hyper Exclusive with only 100 available to mint!" />

					<CompBlockCard style="style1" icon={SvgCheck2} color={"x00d5fe"} title="Amazing Community" text="Tubby Aliens are friendly and fun, and so is our community. Come join in on the fun!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"xfd80bb"} title="Exclusive Events" text="Holder only events held both online and live! Come hang out with the Tubby Crew!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"xfcec5e"} title="Holder Privileges" text="Holders get the right to vote on key decisions. So you have a say on where we go next!" />
					<CompBlockCard style="style1" icon={SvgCheck2} color={"x8c5afd"} title="Beyond Metaverse" text="More than just digital! We're taking Tubby Aliens physical with merch, toys and more!" />

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeWhy;
