// Import images
import ImgFeature1 from '../../../../assets/img/feature1.png';
import ImgFeature1_2x from '../../../../assets/img/feature1-2x.png';
import ImgFeature2 from '../../../../assets/img/feature2.png';
import ImgFeature2_2x from '../../../../assets/img/feature2-2x.png';
import CompBlockCard from '../../../block/Card.js';

// Import svg
import SvgDiscord from '../../../../assets/svg/logo-discord.svg';

const HomeGallery = (className) => {

	return (
		<section className="container container-wide home-gallery overflow-show">
			<div className="container-core">

				<div className="container-row block-flex block-flex-around">

					<div className="block-image">
						<img className="w480-hide" alt="" src={ImgFeature1} srcSet={ImgFeature1_2x + " 2x"} />
						<img className="w480-show" alt="" src={ImgFeature2} srcSet={ImgFeature2_2x + " 2x"} />
					</div>

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeGallery;
