// Import images
import ImgLogoW150 from '../../../assets/img/logo_w150.png';
import ImgLogoW150_2x from '../../../assets/img/logo_w150-2x.png';

import SvgDiscord from '../../../assets/svg/logo-discord.svg';
import SvgTwitter from '../../../assets/svg/logo-twitter.svg';
import SvgOpensea from '../../../assets/svg/logo-opensea.svg';

// Setup component
const HeaderMobile = () => {

	let closeRef: HTMLInputElement | null;

	const closeClick = () => {
		closeRef.click()
	}

	return (

		<div id="header-mobile" className="uk-scope wrapper w800-show">
		{/* <div id="header-mobile-core" className="wrapper-core"> */}
		{/* <div id="header-mobile-core" className="wrapper-core"> */}
		<div id="header-mobile-core" className="wrapper-core" uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; top: 400;dropbar-mode: slide;duration: 5000;">

			<nav id="header-mobile-sticky" className="block-flex uk-navbar-container uk-navbar-transparent uk-navbar" uk-navbar="">

				<div id="header-mobile-logo" className="menu-logo">
					<span className="uk-navbar-item uk-logo" href="">
						<img alt="Logo" src={ImgLogoW150} srcSet={ImgLogoW150_2x} />
					</span>
				</div>

				<div id="header-mobile-links" className="block-flex flex-gap-15">

					<div id="header-mobile-social" className="menu-social block-flex flex-gap-10">

						<a className="uk-navbar-item social-link social-opensea" href="https://opensea.io/collection/tubbyalienofficial" target="_blank">
							<img className="social-icon" src={SvgOpensea} width="40" height="40" uk-svg="" />
						</a>

						<a className="uk-navbar-item social-link social-discord" href="https://discord.gg/knNa8fvkgS" target="_blank">
							<img className="social-icon" src={SvgDiscord} width="40" height="40" uk-svg="" />
						</a>

						<a className="uk-navbar-item social-link social-twitter" href="https://twitter.com/TubbyAlien" target="_blank">
							<img className="social-icon" src={SvgTwitter} width="40" height="40" uk-svg="" />
						</a>

					</div>

					<div id="header-mobile-toggle" className="menu-toggle">
						<a className="header-toggle uk-navbar-toggle" uk-toggle="target: #header-mobile-menu">
							<span className="header-toggle-bar"></span>
							<span className="header-toggle-bar"></span>
							<span className="header-toggle-bar"></span>
						</a>
					</div>

				</div>

			</nav>
			{/* Responsive menu */}

			<div id="header-mobile-menu" className="uk-offcanvas" uk-offcanvas="overlay: true">

				<div id="header-mobile-menu-core" className="uk-offcanvas-bar uk-flex uk-flex-column">

				{/* This is an anchor toggling the off-canvas */}
				<a href="#header-toggle-icon" uk-toggle=""></a>
				<button className="uk-offcanvas-close" type="button" uk-close="" ref={node => { closeRef = node; }}></button>

					<ul className="uk-nav uk-nav-primary uk-margin-auto-vertical">

						<li className="menu-item menu-item-top menu-item-link menu-item-why">
							<a className="" href="#home-why" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">Why</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-roadmap">
							<a className="" href="#home-roadmap" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">Roadmap</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-utility">
							<a className="" href="#home-utility" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">Utility</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-rarity">
							<a className="" href="#home-rarity" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">Rarity</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-team">
							<a className="" href="#home-team" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">Team</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-faq">
							<a className="" href="#home-faq" uk-scroll="" onClick={closeClick}>
								<span className="menu-name">FAQ</span>
								<span className="menu-highlight"></span>
							</a>
						</li>

					</ul>

				</div>
			</div>

		</div>
		</div>
	);
};

export default HeaderMobile;
