// Import images
import ImgLogoW180 from '../../../assets/img/logo_w180.png';
import ImgLogoW180_2x from '../../../assets/img/logo_w180-2x.png';

import SvgDiscord from '../../../assets/svg/logo-discord.svg';
import SvgTwitter from '../../../assets/svg/logo-twitter.svg';
import SvgOpensea from '../../../assets/svg/logo-opensea.svg';

// Setup component
const FooterMain = (props) => {
	return (
		<div id="footer-main" className="uk-scope wrapper block-flex">
		<div id="footer-main-core" className="wrapper-core">

			{/* UIkit - Navbar */}
			<nav className="uk-navbar-container uk-navbar-transparent uk-navbar" uk-navbar="mode: click; boundary-align: true; align: center; delay-hide: 0">

				<div id="footer-main-logo" className="menu-logo uk-navbar-left">
					<a className="uk-navbar-item uk-logo">
						<img alt="Logo" src={ImgLogoW180} srcSet={ImgLogoW180_2x + " 2x"} />
					</a>
				</div>

				<div id="footer-main-social" className="menu-social uk-navbar-right">

					<ul className="uk-navbar-nav block-grid grid-col-2 grid-column-gap-20">

						{/* <li className="menu-item menu-item-top menu-item-link menu-item-team">

							<a className="uk-navbar-item social-link social-opensea" href="">
								<img className="social-icon" src={SvgOpensea} width="40" height="40" uk-svg="" />
							</a>

						</li> */}

						<li className="menu-item menu-item-top menu-item-link menu-item-team block-flex">

							<a className="uk-navbar-item social-link social-discord" href="https://discord.gg/knNa8fvkgS" target="_blank">
								<img className="social-icon" src={SvgDiscord} width="40" height="40" uk-svg="" />
							</a>

						</li>

						<li className="menu-item menu-item-top menu-item-link menu-item-team block-flex">

							<a className="uk-navbar-item social-link social-twitter" href="https://twitter.com/TubbyAlien" target="_blank">
								<img className="social-icon" src={SvgTwitter} width="40" height="40" uk-svg="" />
							</a>

						</li>

					</ul>

				</div>

			</nav>

		</div>
		</div>
	);
};

export default FooterMain;
