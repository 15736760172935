import createDOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'

const Countdown = (props) => {

  // Choose which countdown style to return
  if (props.style == '' || props.style == 'style1') {

    return (
      <div className={"block-countdown block-style1 " + props.class}>

        <div className="block-countdown-core">

          <div className="block-header">

						<h4 className="block-title">{props.title}</h4>

            <div className="block-text">
              <p>{props.text}</p>
            </div>

          </div>

          <div className="block-body">

	          <div className="block-counter">

							<div className="block-flex flex-col-4 flex-column-gap-25" uk-countdown={"date: " + props.date}>

								<div className="block-counter-item">
										<div className="item-number uk-countdown-number uk-countdown-days"></div>
										<div className="item-countdown uk-countdown-label">Days</div>
								</div>

								<div className="block-counter-item">
										<div className="item-number uk-countdown-number uk-countdown-hours"></div>
										<div className="item-countdown uk-countdown-label">Hours</div>
								</div>

								<div className="block-counter-item">
										<div className="item-number uk-countdown-number uk-countdown-minutes"></div>
										<div className="item-countdown uk-countdown-label">Mins</div>
								</div>

								<div className="block-counter-item">
										<div className="item-number uk-countdown-number uk-countdown-seconds"></div>
										<div className="item-countdown uk-countdown-label">Secs</div>
								</div>

							</div>

						</div>

          </div>

        </div>

      </div>
    );

  } else if (props.style == 'style2') {

    return (
      <div className="block-countdown block-style2">
      </div>
    );
  }
};

Countdown.defaultProps = {
  style : '',
  class : '',
  title : 'Sample Title Reach',
  text  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
  data  : '',
}

export default Countdown;
