// Import dependencies
import HomeHeader1 from './part/HomeHeader1.js';
import HomeHeader2 from './part/HomeHeader2.js';
import HomeFeatured from './part/HomeFeatured.js';
import HomeWhy from './part/HomeWhy.js';
import HomeRoadmap from './part/HomeRoadmap.js';
import HomeUtility from './part/HomeUtility.js';
import HomeRarity from './part/HomeRarity.js';
import HomeTeam from './part/HomeTeam.js';
import HomeGallery from './part/HomeGallery.js';
import HomeFaq from './part/HomeFaq.js';

const Home = () => {

	// Add page class
	document.body.classList.add('page-home');
//	document.body.classList.add('mint-on');
//	document.body.classList.add('mint-off');

	return (
		<>
			<HomeHeader2 />
			<HomeFeatured />
			<HomeWhy />
			<HomeGallery />
			<HomeRoadmap />
			<HomeUtility />
			<HomeRarity />
			<HomeTeam />
			<HomeFaq />
		</>
	);
};

export default Home;
