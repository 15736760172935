// Import dependencies
import './App.scss';
import HeaderMint from './components/global/header/HeaderMint.js';
import HeaderMain from './components/global/header/HeaderMain.js';
import HeaderMobile from './components/global/header/HeaderMobile.js';
import FooterMain from './components/global/footer/FooterMain.js';
import FooterCopyright from './components/global/footer/FooterCopyright.js';
import PageHome from './components/page/home/Home.js';

const App = () => {
  return (
    <>
			{/* Import header */}
			<header id="header">
				{/* <HeaderMint /> */}
				<HeaderMain />
				<HeaderMobile />
			</header>

			{/* Import main */}
			<main id="main">
				<PageHome />
			</main>

			{/* Import footer */}
			<footer id="footer">
				<FooterMain />
				{/* <FooterCopyright /> */}
			</footer>
    </>
  );
};

export default App;
