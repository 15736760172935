// Import images
import ImgRoadmap1 from '../../../../assets/img/roadmap1.png';
import ImgRoadmap1_2x from '../../../../assets/img/roadmap1-2x.png';
import ImgRoadmap2 from '../../../../assets/img/roadmap2.png';
import ImgRoadmap2_2x from '../../../../assets/img/roadmap2-2x.png';
import ImgRoadmap3 from '../../../../assets/img/roadmap3.png';
import ImgRoadmap3_2x from '../../../../assets/img/roadmap3-2x.png';
import ImgRoadmap4 from '../../../../assets/img/roadmap4.png';
import ImgRoadmap4_2x from '../../../../assets/img/roadmap4-2x.png';
import CompBlockCard from '../../../block/Card.js';

// Import svg
import SvgDiscord from '../../../../assets/svg/logo-discord.svg';

const HomeRoadmap = (className) => {

	return (
		<section id="home-roadmap" className="container home-roadmap overflow-show">
			<div className="container-core">

				{/* Title section for features section */}
				<div className="container-row block-title">

					<div className="block-header">
						<h5 className="title-info style1 xff6f91 font-narum"><span>A long-term plan!</span></h5>
						<h2 className="title-subsection font-spaceboy">Roadmap</h2>

						<div className="block-text title-text">
              <p>We want Tubby Aliens to take over the world, and have an amazing plan to make this happen!</p>
            </div>
					</div>

				</div>
				{/* .container-row.block-title */}

				<div className="container-row">

						<div className="block-image">
							<img className="w480-hide" alt="" src={ImgRoadmap3} srcSet={ImgRoadmap3_2x + " 2x"} />
							<img className="w480-show" alt="" src={ImgRoadmap4} srcSet={ImgRoadmap4_2x + " 2x"} />
						</div>

				</div>
				{/* .container-row.block-grid */}

			</div>
		</section>
	);
};

export default HomeRoadmap;
